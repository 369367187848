<template>
  <div class="articleList">
    <!-- 文章管理 -->
    <div class="articleTop">
      <div class="allStatus">
        <ul>
          <li :class="item.title === selectTypeArticleStatus ? 'active' : ''" v-for="item in statusList" :key="item.id"
            @click="selectTypeArticle(item.title)">
            <span>{{ item.name }}</span>
          </li>
        </ul>
      </div>
      <div class="addBtn">
        <!-- <el-button type="primary"  size="small" @click="addArticle">新建</el-button> -->
      </div>
    </div>
    <div class="articleContent">
      <div class="searchBtn">
        <el-input placeholder="文章标题" v-model="searchInfo.title" clearable size="small">
        </el-input>
        <el-date-picker v-model="timeList" value-format="yyyy-MM-dd HH:mm:ss" type="daterange" range-separator="-"
          start-placeholder="开始日期" end-placeholder="结束日期" size="small">
        </el-date-picker>
        <el-cascader :options="articleTypeList" v-model="searchInfo.categoryId"
          :props="{ label: 'title', value: 'id', checkStrictly: true, emitPath: false }" :show-all-levels="false"
          change-on-select size="small" style="width:100%" @change="getCheckedNodes($event)"></el-cascader>
        <!-- <el-select v-model="searchInfo.categoryId" placeholder="分类" size="small">
          <el-option :label="item.title" :value="item.id" v-for="item in articleTypeList" :key="item.id">
          </el-option>
        </el-select> -->
        <el-input placeholder="发布用户" v-model="searchInfo.author" clearable size="small">
        </el-input>
        <el-button type="primary" size="small" @click="search">查询</el-button>
        <el-button type="primary" size="small" @click="addArticle">新建</el-button>
        <el-button type="primary" size="small" @click="deleteArticle">删除</el-button>
      </div>
      <div>
        <template>
          <el-table @selection-change="handleSelectionChange" ref="multipleTable" :data="tableData" stripe
            tooltip-effect="dark" style="width: 100%" border>
            <el-table-column type="selection" width="50">
            </el-table-column>
            <el-table-column prop="id" label="ID" width="80">
            </el-table-column>
            <el-table-column prop="title" label="标题">
            </el-table-column>
            <el-table-column prop="categorys" label="分类">
              <!-- <template slot-scope="scope">
                <span v-for="item in scope.row.category" :key="item.id">{{ item.title }} &nbsp;</span>

              </template> -->
            </el-table-column>
            <el-table-column prop="tag" label="标签">
            </el-table-column>
            <el-table-column prop="author" label="作者">
            </el-table-column>
            <el-table-column prop="created" label="发布时间">
            </el-table-column>
            <el-table-column prop="modified" label="更新时间">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <!-- <template slot-scope="scope">{{ scope.row.date }}</template> -->
                <el-button type="text" @click="ToUpdateArticle(scope.row.id)">编辑</el-button>
                <template v-if="scope.row.status === 'normal'">
                  
                  <el-button type="text" @click="toDetail(scope.row)">查看</el-button>
                  <el-button type="text" @click="updateArticle(scope.row, 'draft')">草稿</el-button>
                  <el-button type="text" @click="updateArticle(scope.row, 'trash')">垃圾箱</el-button>
                </template>
                <template v-if="scope.row.status === 'draft'">
                  <el-button type="text" @click="updateArticle(scope.row, 'normal')">发布</el-button>
                  <el-button type="text" @click="updateArticle(scope.row, 'trash')">垃圾箱</el-button>
                </template>
                <template v-if="scope.row.status === 'trash'">
                  <!-- <el-button type="text" @click="updateArticle(scope.row, 'normal')">发布</el-button> -->
                  <el-button type="text" @click="updateArticle(scope.row, 'draft')">草稿</el-button>
                  <el-button type="text"  @click="deleteDraft(scope.row.id)">删除</el-button>
                </template>
                <!-- <el-button type="text" v-if="scope.row.status === 'normal'" @click="updateArticle(scope.row, 'draft')">草稿</el-button>
                <el-button type="text" v-if="scope.row.status === 'normal'"  @click="updateArticle(scope.row, 'trash')">垃圾箱</el-button> -->
                <!-- <el-button type="text" @click="ToUpdateArticle(scope.row.id)">查看</el-button> -->
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="block">
          <el-pagination @current-change="handleCurrentChange" :current-page="paging.pageNum" :page-size="paging.pageSize"
            layout="total,  prev, pager, next, jumper" :total="paging.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articleListAPI, deleteArticleAPI, updateArticleAPI } from '@/api/article/TCCArticle'
import { articleTypeListAPI } from '@/api/article/TCCType'

export default {
  data() {
    return {
      selectTypeArticleStatus: '', // 默认选中全部
      // 状态的数组
      statusList: [
        { id: 1, name: '全部', title: '' },
        { id: 2, name: '已发布', title: 'normal' },
        { id: 3, name: '草稿', title: 'draft ' },
        { id: 4, name: '垃圾箱', title: 'trash' }
      ],
      articleTypeList: [],
      // 分页
      paging: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      tableData: [],
      selectList: [],
      timeList: [], // 选择的时间数组
      searchInfo: {
        author: null, // 作者
        title: null, // 标题
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        status: null, // 状态
        categoryId: 0, // 文章分类id
        // username: null
      }
    }
  },
  methods: {
    handleCurrentChange(val) {
      this.paging.pageNum = val;
      this.getArticleList();
      // console.log(`当前页: ${val}`);
    },
    // 用户选择
    handleSelectionChange(val) {
      // console.log(val);
      this.selectList = val;
    },
    // 分类节点选择
    getCheckedNodes(val) {
      console.log(val)
    },
    // 根据草稿查询等
    selectTypeArticle(val) {
      this.paging.p = 1;
      this.searchInfo.status = val;
      this.selectTypeArticleStatus = val;
      this.getArticleList();
    },
    // 搜索
    search() {
      this.paging.p = 1;
      // console.log(this.searchInfo);
      this.getArticleList();

    },
    // 修改文章状态
    async updateArticle(val, val2) {
      // console.log(val);
      val.status = val2;
      const res = await updateArticleAPI(JSON.stringify(val));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: '操作成功！',
          type: 'success'
        });
        this.getArticleList();
      } else {
        this.$message({
          showClose: true,
          message: '操作失败！',
          type: 'error' + res.msg
        });
      }
    },
    // 跳转到修改文章
    ToUpdateArticle(val) {
      console.log(val);
      this.$router.push({
        path: '/admin/TCCArticle/write',
        query: {
          id: val
        }
      })
    },
    // 点击新增
    addArticle() {
      this.$router.push('/admin/TCCArticle/write')
    },
    // 获取文章列表
    async getArticleList() {

      let info = {
        pageNum: this.paging.pageNum,
        pageSize: this.paging.pageSize,
        ...this.searchInfo
      }
      // if (info.categoryId === 0) {
      //   info.categoryId = null
      // }
      if (this.timeList && this.timeList.length > 0) {
        info.startTime = this.timeList[0];
        info.endTime = this.timeList[1];
      }
      const res = await articleListAPI(info);
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.list;
        this.paging.total = res.data.total;
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '列表获取失败!'
        });
      }
    },
    // 获取文章分类列表
    async getArticleTypeList() {
      const res = await articleTypeListAPI({ pageNum: 1, pageSize: 10000 });
      console.log(res);
      if (res.code === 200) {
        this.articleTypeList = res.data.list;
        this.articleTypeList = [{ id: 0, title: '全部分类' }, ...this.articleTypeList]
      }
    },
    // 删除文章
    async deleteArticle() {
      // console.log(this.selectList);
      if (this.selectList.length > 0) {

        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let list = [];
          this.selectList.forEach(item => {
            list.push(item.id);
          })
          const res = await deleteArticleAPI(JSON.stringify(list));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              type: 'success',
              showClose: true,
              message: '删除成功!'
            });
            this.getArticleList();
          } else {
            this.$message({
              type: 'error',
              showClose: true,
              message: '删除失败!' + res.msg
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          });
        });
      } else {
        this.$message({
          showClose: true,
          message: '请选择需要删除的数据！'
        });
      }
    },
    // 删除垃圾箱中的文章
    deleteDraft(val) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const list = [val];
          const res = await deleteArticleAPI(JSON.stringify(list));
          console.log(res);
          if (res.code === 200) {
            this.$message({
              type: 'success',
              showClose: true,
              message: '删除成功!'
            });
            this.getArticleList();
          } else {
            this.$message({
              type: 'error',
              showClose: true,
              message: '删除失败!' + res.msg
            });
          }
        }).catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          });
        });
    },
    // 查看文章
    toDetail(val) {
      
      sessionStorage.setItem('secondName', val.categorys)
      this.$router.push(`/trends/detail/${val.id}`)
    }
  },
  created() {
    this.getArticleList();
    this.getArticleTypeList();
  }
}
</script>

<style lang="scss" scoped>
.articleList {
  .articleTop {
    display: flex;
    justify-content: space-between;

    .allStatus {
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        margin-bottom: 20px;

        li {
          cursor: pointer;
          // padding-right:10px;
          margin-right: 10px;
          padding-right: 10px;
          border-right: 2px solid rgb(183, 182, 182);

          &:last-child {
            border-right: 0px;
          }
        }

        .active {
          color: var(--customManagement-color);
          font-weight: 600;
        }
      }
    }
  }

  .articleContent {
    // .searchBtn {
    //   margin: 10px 0px;
    // }
    // .el-table{
    //   min-height: 500px;
    // }
  }
}
</style>